import { Dialog, Transition } from '@headlessui/react';
import Button from 'components/button';
import { useAtom } from 'jotai';
import confirmationAtom from 'lib/atoms/confirmationAtom';
import React from 'react';

const ConfirmationAlert = () => {
  const [confirmation, setConfirmation] = useAtom(confirmationAtom);
  const onClose = () => setConfirmation(null);
  const onConfirm = () => {
    confirmation?.onConfirm();
    onClose();
  };

  if (!confirmation) return null;

  return (
    <Transition appear show as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {confirmation?.title || 'Confirmation required'}
                </Dialog.Title>
                <div className="mt-2">{confirmation.message}</div>

                <div className="mt-4 flex gap-2 justify-end">
                  <Button variant="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button onClick={onConfirm}>Confirm</Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConfirmationAlert;
