import { PropsWithChildren } from 'react';
import Link from 'next/link';
import clsx from 'clsx';

type HeaderLinkProps = PropsWithChildren<{
  href?: string;
  current: string;
  onClick?(): void;
}>;

const HeaderLink: React.FC<HeaderLinkProps> = ({
  href,
  current,
  children,
  onClick,
}) => {
  const isActive = href === current;

  const twClasses = clsx([
    'block text-left w-full px-3 py-2 my-1 rounded-md font-medium',
    isActive
      ? 'bg-gray-900 text-white'
      : 'text-gray-300 bg-transparent hover:bg-gray-600 hover:text-white',
  ]);

  if (href === undefined) {
    return (
      <button className={twClasses} onClick={onClick}>
        {children}
      </button>
    );
  }

  return (
    <Link className={twClasses} href={href}>
      {children}
    </Link>
  );
};

export default HeaderLink;
