import Avatar from 'components/avatar';
import React from 'react';
import { trpc } from 'utils/trpc';

const UserCard: React.FC<{ email?: string; compact?: boolean }> = ({
  email,
  compact = false,
}) => {
  if (!email) return null;

  const employees = trpc.dictionary.employees.useQuery(undefined, {
    staleTime: Infinity,
  });

  const employee = employees.data?.find((e) => e.workEmail === email);
  const name = employee?.displayName || email;

  const tooltipProps =
    compact && employees.isSuccess && name
      ? {
          'data-tooltip-content': name,
          'data-tooltip-delay-show': 500,
          'data-tooltip-id': 'assessor-tooltip',
        }
      : {};

  return (
    <div className="flex items-center" {...tooltipProps}>
      <Avatar src={employee?.photoUrl} alt={name} />
      {!compact && (
        <div className="ml-2 text-sm leading-tight">
          {employee?.displayName && <h6 className="font-bold">{name}</h6>}
          {email}
        </div>
      )}
    </div>
  );
};

export default UserCard;
