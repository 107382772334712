import { Menu, Transition } from '@headlessui/react';
import { signIn, signOut, useSession } from 'next-auth/react';
import React, { Fragment, PropsWithChildren } from 'react';
import { useCheckAdminPermissions } from 'lib/hooks/permissions/userPermissons';
import { Disclosure } from '@headlessui/react';
import { useRouter } from 'next/router';
import UserCard from 'components/user-card';
import Button from 'components/button';
import Image from 'next/image';
import HeaderLink from 'components/layouts/header-link';
import Link from 'next/link';
import clsx from 'clsx';
import ConfirmationAlert from 'components/confirmation-alert';
import Spinner from 'components/spinner';
import { env } from 'env/client.mjs';
import {
  ChevronDown,
  GhostIcon,
  LogOut,
  MenuIcon,
  PlusIcon,
  ScrollTextIcon,
  XIcon,
} from 'lucide-react';

const navigation = [
  { name: 'Dashboard', href: '/', admin: false, mainNav: true },
  {
    name: 'Assessments',
    href: '/admin/assessments',
    admin: true,
    mainNav: true,
  },
  {
    name: 'Competencies',
    href: '/admin/competencies',
    admin: true,
    mainNav: false,
  },
  { name: 'Users', href: '/admin/users', admin: true, mainNav: false },
  { name: 'Employees', href: '/admin/employees', admin: true, mainNav: false },
];

const BaseLayout: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const session = useSession();
  const isAdmin = useCheckAdminPermissions();
  const router = useRouter();

  if (session.status === 'loading') {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  if (session.status === 'unauthenticated') {
    signIn(env.NEXT_PUBLIC_DEFAULT_AUTH_PROVIDER);
    return null;
  }
  const handleSignOut = () => {
    signOut();
    router.push('/');
  };

  return (
    <>
      <Disclosure
        as="div"
        className="sticky top-0 z-[100] bg-gray-700 min-w-sm"
      >
        {({ open }) => (
          <>
            <div className="px-4">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <h1 className="text-md font-semibold">
                    <Link className="text-white flex" href="/">
                      <div className="flex items-center gap-2">
                        <Image
                          src="/icon-192x192.png"
                          width="30"
                          height="30"
                          alt="Provectus"
                        />
                        Assessor
                      </div>
                    </Link>
                  </h1>
                  <div className="hidden md:block">
                    <div className="flex items-baseline ml-10 gap-x-2">
                      {navigation.map(({ name, href, admin, mainNav }) => {
                        if ((admin && !isAdmin) || !mainNav) {
                          return null;
                        }
                        return (
                          <HeaderLink
                            key={href}
                            href={href}
                            current={router.pathname}
                          >
                            {name}
                          </HeaderLink>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="flex -mr-2 gap-2 items-center">
                  {session && (
                    <>
                      {isAdmin && router.pathname !== '/admin/assessments/new' && (
                        <Button
                          variant="primary"
                          onClick={() => router.push('/admin/assessments/new')}
                        >
                          <PlusIcon size="18" /> Assessment
                        </Button>
                      )}

                      {/* Profile dropdown */}
                      <Menu
                        as="div"
                        className="relative text-left hidden md:inline-block "
                      >
                        <Menu.Button
                          className={clsx([
                            'inline-flex  w-full justify-center rounded-xl items-center',
                            'pl-2 pr-3 py-1.5 text-sm font-medium',
                            'bg-gray-800 bg-opacity-20 text-white hover:bg-opacity-30',
                            'focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
                          ])}
                        >
                          <UserCard email={session.data?.user?.email} compact />
                          <ChevronDown
                            size="14"
                            className="ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {isAdmin && (
                              <div className="px-1 py-1 ">
                                {navigation.map(
                                  ({ name, href, admin, mainNav }) => {
                                    if (!admin || mainNav) {
                                      return null;
                                    }
                                    return (
                                      <Menu.Item key={href}>
                                        {({ active }) => (
                                          <Link
                                            href={href}
                                            className={clsx([
                                              'text-gray-900 group flex gap-2 w-full items-center rounded-md px-2 py-2',
                                              active && 'bg-gray-200',
                                            ])}
                                          >
                                            <GhostIcon size={18} />
                                            {name}
                                          </Link>
                                        )}
                                      </Menu.Item>
                                    );
                                  }
                                )}
                              </div>
                            )}
                            <div className="px-1 py-1 ">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="/api-docs"
                                    className={clsx([
                                      'text-gray-900 group flex gap-2 w-full items-center rounded-md px-2 py-2',
                                      active && 'bg-gray-200',
                                    ])}
                                  >
                                    <ScrollTextIcon size={18} />
                                    API docs
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => signOut()}
                                    className={clsx([
                                      'text-gray-900 group flex gap-2 w-full items-center rounded-md px-2 py-2',
                                      active && 'bg-gray-200',
                                    ])}
                                  >
                                    <LogOut size={18} />
                                    Sign out
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </>
                  )}
                  <div
                    className={clsx([
                      'md:hidden flex justify-center rounded-xl items-center',
                      'w-11 h-11 text-xl font-medium cursor-pointer',
                      'bg-gray-800 bg-opacity-20 text-white hover:bg-opacity-30',
                      'focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
                    ])}
                  >
                    <Disclosure.Button as="div">
                      {open ? <XIcon /> : <MenuIcon />}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </div>
            <Disclosure.Panel as="div" className="md:hidden">
              <div className="px-3 pb-4 gap-2">
                {navigation.map(({ name, href, admin }) => {
                  if (admin && !isAdmin) {
                    return null;
                  }
                  return (
                    <Disclosure.Button key={href} as="div">
                      <HeaderLink href={href} current={router.pathname}>
                        {name}
                      </HeaderLink>
                    </Disclosure.Button>
                  );
                })}
                <Disclosure.Button as="div">
                  <HeaderLink onClick={handleSignOut} current={router.pathname}>
                    Sign Out
                  </HeaderLink>
                </Disclosure.Button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <main className="block sm min-w-sm">{children}</main>
      <ConfirmationAlert />
    </>
  );
};

export default BaseLayout;
