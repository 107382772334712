import React from 'react';
import { Tooltip as VendorTooltip } from 'react-tooltip';

const Tooltip: React.FC = () => (
  <VendorTooltip
    id="assessor-tooltip"
    className="border-r-2 max-w-[340px] text-xs text-center"
    render={({ content }) => <span>{content}</span>}
  />
);

export default Tooltip;
