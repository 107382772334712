import { SessionProvider } from 'next-auth/react';
import type { AppType } from 'next/app';
import type { Session } from 'next-auth';
import { ChakraProvider } from '@chakra-ui/react';
import BaseLayout from 'components/layouts/base-layout';
import { trpc } from 'utils/trpc';
import Head from 'next/head';
import 'react-tooltip/dist/react-tooltip.css';
import 'styles/globals.css';

import { Toaster } from 'react-hot-toast';
import Tooltip from '../components/tooltip';

const MyApp: AppType<{ session: Session | null }> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  return (
    <SessionProvider session={session}>
      <ChakraProvider>
        <Head>
          <title>Assessment Tool</title>
        </Head>
        <BaseLayout>
          <Component {...pageProps} />
          <Toaster />
          <Tooltip />
        </BaseLayout>
      </ChakraProvider>
    </SessionProvider>
  );
};

export default trpc.withTRPC(MyApp);
