import { atom } from 'jotai';
import React from 'react';

export interface Confirmation {
  title?: string;
  message: React.ReactNode;
  onConfirm: () => void;
}

export default atom<Confirmation | null>(null);
