import clsx from 'clsx';
import { useConfirm } from 'lib/hooks/use-confirm';
import React, { PropsWithChildren } from 'react';

type ButtonProps = PropsWithChildren<{
  confirmationMessage?: React.ReactNode;
  onClick?(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  className?: string;
  variant?: 'primary' | 'secondary' | 'danger' | 'ghost';
  size?: 'xs' | 'sm' | 'md';
  isLoading?: boolean;
  disabled?: boolean;
  title?: string;
  dataset?: {
    [dataAttribute: `data-${string}`]: string;
  };
}>;

const Button: React.FC<ButtonProps> = ({
  confirmationMessage,
  onClick,
  className,
  children,
  variant = 'primary',
  size = 'md',
  isLoading,
  disabled,
  title,
  dataset,
}) => {
  const confirm = useConfirm();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!onClick) return;
    if (confirmationMessage) {
      confirm({
        message: confirmationMessage,
        onConfirm: () => onClick(e),
      });
      return;
    }
    onClick(e);
  };

  const twClasses = clsx([
    'font-medium text-center inline-flex items-center justify-center gap-2 whitespace-nowrap transition-colors duration-200 ease-in-out',
    // variants
    variant === 'primary' && 'bg-blue-700 hover:bg-blue-800 text-white',
    variant === 'secondary' && 'bg-gray-700 hover:bg-gray-800 text-white',
    variant === 'danger' && 'bg-red-700 hover:bg-red-800 text-white',
    variant === 'ghost' && 'bg-transparent hover:bg-gray-100 text-gray',

    // sizes
    size === 'xs' && 'px-2 h-5 min-w-[24px] text-xs leading-6 rounded',
    size === 'sm' && 'px-2 h-8 min-w-[32px] text-sm leading-8 rounded',
    size === 'md' && 'px-3 h-10 min-w-[40px] text-md leading-10 rounded-md',

    // disabled
    disabled && 'opacity-30 cursor-not-allowed',

    // default focus styles
    'focus:ring-2 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800',

    // custom classes
    className,
  ]);

  return (
    <button
      onClick={handleClick}
      className={twClasses}
      disabled={disabled}
      title={title}
      {...dataset}
    >
      {isLoading && 'Loading...'}
      {children}
    </button>
  );
};

export default Button;
