import Image from 'next/image';
import { z } from 'zod';
import clsx from 'clsx';

interface AvatarProps {
  src?: string | null;
  alt: string;
}

const initials = (alt: string) => {
  let result = 'N/A';
  if (z.string().email().safeParse(alt).success) {
    result = alt.slice(0, 2);
  } else {
    const [firstName, lastName] = alt.split(' ');

    if (firstName && lastName) {
      result = `${firstName.charAt(0)}${lastName.charAt(0)}`;
    }
  }

  return result.toUpperCase();
};

const Avatar: React.FC<AvatarProps> = ({ src, alt }) => {
  return (
    <div
      className={clsx([
        'flex items-center justify-center',
        'h-8 w-8 rounded-lg overflow-hidden relative',
        'bg-gray-100',
      ])}
    >
      {src && <Image src={src} alt={alt} fill />}
      {!src && <span className="text-gray-700">{initials(alt)}</span>}
    </div>
  );
};

export default Avatar;
